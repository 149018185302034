<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Media Library {{ assets && assets.length && '/' }}
        <span v-if="assets && assets.length">{{
          assets[0].mediaLibrary.name
        }}</span>
      </div>

      <div>
        <cs-button
          variant="primary"
          fill="outline"
          :disabled="disable"
          @click="showAddAssetModal"
          >Upload File</cs-button
        >
      </div>
    </div>
    <div
      v-if="assets && assets.length"
      class="ecs-dashboard__search d-flex flex-row"
    >
      <cs-search-bar
        v-model="val"
        placeholder="Search"
        class="ecs-media__search-bar"
      />
      <i
        class="cs-icons-grid ecs-media__search-bar-icon"
        :class="{ activeClass: active === 0 }"
        @click="listView"
      ></i>
      <i
        class="cs-icons-grid-line ecs-media__search-bar-icon"
        :class="{ activeClass: active === 1 }"
        @click="gridView"
      ></i>
    </div>
    <!-- Empty State -->
    <div v-if="assets && !assets.length">
      <app-empty-state
        title="No Media Assets"
        :disable="disable"
        description="You have not uploaded any asset yet."
        button-text="Add New Asset"
        @add-action="showAddAssetModal"
      />
    </div>
    <!-- Spinner -->
    <div v-if="$apollo.queries.assets.loading" class="ecs-spinner__wrapper">
      <cs-spinner class="ecs-spinner" />
    </div>
    <div
      v-if="assets && assets.length && active === 1"
      class="ecs-dashboard__table-head"
    >
      <b-table
        :items="assets"
        :fields="fields"
        borderless
        hover
        striped
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        :busy="$apollo.queries.assets.loading"
        @row-clicked="onOpenAssetDetail"
      >
        <template #table-busy>
          <div class="ecs-dashboard__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <!-- Name -->
        <template #cell(name)="data">
          <app-asset-name
            :file-type="data.item.content_type"
            :file-name="data.item.name"
          />
        </template>
        <!-- Created at  -->
        <template #cell(created_at)="data">
          <div>{{ data.item.created_at | moment('DD MMM YYYY') }}</div>
        </template>
        <!-- Size  -->
        <template #cell(original_size)="data">
          {{ getSize(data.item.original_size) }}
        </template>
        <!-- Tags  -->
        <template #cell(tags)="data">
          <div class="d-flex flex-row gap-1">
            <div
              v-for="(tag, index) in data.item.tags"
              :key="`${tag.key}-${index}`"
              class="d-flex flex-row"
            >
              <span class="ecs-key-tag d-flex flex-row"
                ><cs-tag>{{ tag.key }}</cs-tag></span
              ><span class="ecs-value-tag"
                ><cs-tag>{{ tag.value }}</cs-tag></span
              >
            </div>
          </div>
        </template>
        <!-- Action -->
        <template #cell(action)="data">
          <i
            v-if="!disable"
            :id="`showPopover${data.index}`"
            class="cs-icons-options ecs_icon-class"
            tabindex="-1"
          >
          </i>
          <b-popover
            ref="actionPopover"
            :target="`showPopover${data.index}`"
            triggers="click blur"
            custom-class="bg-dark"
            placement="leftbottom"
          >
            <div class="ecs-dashboard__popover">
              <div @click.stop="showEditAssetModal(data.item)">Edit File</div>
              <div @click.stop="removeFile(data.item)">Remove File</div>
            </div>
          </b-popover>
        </template>
      </b-table>
    </div>
    <div v-if="assets && assets.length && active === 0" class="ecs-media__grid">
      <div v-for="(asset, index) in assets" :key="`${asset.name}-${index}`">
        <app-media-preview
          :id="asset.id"
          :asset="asset"
          @deleted-asset-preview="refreshList"
          @update-after-edit="closeEditAfterUpdate"
        />
      </div>
    </div>
    <app-delete-asset
      :item="item"
      :show-delete="showDelete"
      @close-delete-asset-modal="closeDeleteAssetModal"
      @deleted-asset="refreshList"
    />
    <app-add-asset
      :show-add="showAdd"
      :media-library-id="$route.params.mediaid"
      @close-add-asset-modal="closeAddAssetModal"
      @created-asset="refreshList"
    />
    <app-edit-media-library-asset-modal
      v-if="assets"
      :show-update-media-library-asset="showUpdateMediaLibraryAsset"
      :asset="asset"
      @close-update-media-library-asset="closeUpdateMediaLibraryAssetModal"
    />
  </div>
</template>

<script>
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';
import ListMediaLibraryAsset from '@/gql/medialibrary/ListMediaLibraryAsset.gql';
import AppAssetName from '@/components/medialibrary/AssetName.vue';
import AppMediaPreview from '@/components/medialibrary/MediaPreview.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppDeleteAsset from '@/components/medialibrary/DeleteMediaLibraryAsset.vue';
import AppAddAsset from '@/components/medialibrary/AddMediaLibraryAsset.vue';
import AppEditMediaLibraryAssetModal from '@/components/medialibrary/EditMediaLibraryAssetModal.vue';

export default {
  components: {
    AppAssetName,
    AppMediaPreview,
    AppEmptyState,
    AppDeleteAsset,
    AppAddAsset,
    AppEditMediaLibraryAssetModal,
  },
  apollo: {
    assets: {
      query: ListMediaLibraryAsset,
      variables() {
        return {
          mediaLibraryId: this.$route.params.mediaid,
        };
      },
    },
  },
  data() {
    return {
      disable: null,
      val: '',
      showUpdateMediaLibraryAsset: false,
      active: 0,
      item: {},
      asset: {},
      showDelete: false,
      showAdd: false,
      fields: [
        {
          key: 'name',
          label: 'Name',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Upload Time',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'original_size',
          label: 'Original Size',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'tags',
          label: 'Tag',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'action',
          label: 'Action',
          thClass: 'ecs-dashboard__table-head',
        },
      ],
    };
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    closeEditAfterUpdate() {
      this.$apollo.queries.assets.refetch();
    },
    closeUpdateMediaLibraryAssetModal(val) {
      if (val) {
        this.$apollo.queries.assets.refetch();
      }
      this.showUpdateMediaLibraryAsset = false;
    },
    showEditAssetModal(val) {
      this.asset = val;
      this.showUpdateMediaLibraryAsset = true;
    },
    showAddAssetModal() {
      this.showAdd = true;
    },
    listView() {
      this.active = 0;
    },
    gridView() {
      this.active = 1;
    },
    getSize(val) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (!val || val < 0) return '0 bytes';
      const i = parseInt(Math.floor(Math.log(val) / Math.log(1024)), 10);
      if (i === 0) return `${val} ${sizes[i]}`;
      return `${(val / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    removeFile(d) {
      this.item = d;
      this.showDelete = true;
    },
    closeDeleteAssetModal() {
      this.showDelete = false;
    },
    closeAddAssetModal() {
      this.showAdd = false;
    },
    refreshList() {
      this.$apollo.queries.assets.refetch();
    },
    onOpenAssetDetail(asset) {
      this.$router.push({
        name: 'MediaLibraryAssetDetail',
        params: {
          mediaid: this.$route.params.mediaid,
          assetid: asset.id,
        },
      });
    },
  },
};
</script>

<style scoped>
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-media__grid {
  background: var(--cs-gray-01);
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 20px;
  flex-wrap: wrap;
  border-radius: 5px;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.activeClass {
  color: var(--cs-primary-base);
}
.ecs-media__search-bar {
  width: 100%;
}
.ecs-media__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}
.ecs-key-tag >>> .cs-tag {
  border-radius: 3px 0px 0px 3px;
  background-color: var(--cs-gray-05) !important;
}
.ecs-value-tag >>> .cs-tag {
  border-radius: 0px 3px 3px 0px;
  background-color: var(--cs-gray-04) !important;
  margin-left: 1px;
}
</style>
