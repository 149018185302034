<template>
  <div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Connect your source cloud provider and select the video you want to
      analyze.
    </div>
    <div class="cs-textstyle-detail-heading my-2">Select Source</div>
    <div class="ecs-video-insights__list">
      <div v-for="(item, index) in items" :key="index">
        <div
          :class="active === index ? 'ecs-video-insights__active-items' : ''"
          class="ecs-video-insights__non-active-items"
          @click="toggleSource(index, item)"
        >
          <div>
            <span v-if="!item.img">{{ item.text }}</span
            ><img
              v-else
              :src="item.img"
              :class="item.text === 'AWS' ? 'ecs-video-insights__aws-img' : ''"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="ecs-video-insights__input-wrapper my-2">
      <div v-if="active === 0" class="ecs-video-insights__input-wrapper--url">
        <cs-input
          v-model="sourceUrl"
          label="Enter URL"
          placeholder="http://example.com/assests/videos"
          class="ecs-video-insights__input-wrapper-inputs"
          required
        />
      </div>
      <div v-else class="ecs-video-insights__input-wrapper--secret-keys">
        <div class="ecs-video-insights__input-wrapper--secret-keys__inputs">
          <cs-input
            v-model="sourceBucketName"
            :label="`${items[active].text} Bucket Name`"
            class="ecs-video-insights__input-wrapper-inputs"
            required
          />
          <cs-input
            v-model="sourceBucketPath"
            :label="`${items[active].text} Bucket Path`"
            class="ecs-video-insights__input-wrapper-inputs"
            required
          />
        </div>
        <div class="ecs-video-insights__input-wrapper--secret-keys__inputs">
          <cs-input
            v-model="sourceAccessKeyId"
            :label="`${items[active].text} Access Key ID`"
            class="ecs-video-insights__input-wrapper-inputs"
            required
          />
          <cs-input
            v-model="sourceSecretAccessKey"
            :label="`${items[active].text} Secret Access Key`"
            class="ecs-video-insights__input-wrapper-inputs"
            required
          />
        </div>
      </div>
    </div>
    <cs-button class="ecs-button" :disabled="!valid" @click="nextStep">
      Continue
    </cs-button>
  </div>
</template>

<script>
const awsImgUrl = require('@/assets/images/AWS.svg');
const gcpImgUrl = require('@/assets/images/gcp.svg');
const azureImgUrl = require('@/assets/images/Azure.svg');
const doImgUrl = require('@/assets/images/do.svg');

export default {
  data() {
    return {
      active: 0,
      items: [
        {
          text: 'URL',
          sourceProvider: 'URL',
        },
        {
          text: 'AWS',
          sourceProvider: 'AWS',
          img: awsImgUrl,
        },
        {
          text: 'Google Cloud',
          sourceProvider: 'GCP',
          img: gcpImgUrl,
        },
        {
          text: 'Digital Ocean',
          sourceProvider: 'DIGITALOCEAN',
          img: doImgUrl,
        },
        {
          text: 'Azure',
          sourceProvider: 'AZURE',
          img: azureImgUrl,
        },
      ],
      sourceUrl: null,
      sourceBucketName: null,
      sourceBucketPath: null,
      sourceAccessKeyId: null,
      sourceSecretAccessKey: null,
    };
  },
  computed: {
    valid() {
      if (this.active === 0) {
        return !!this.sourceUrl;
      }
      if (this.active !== 0) {
        return (
          !!this.sourceAccessKeyId &&
          !!this.sourceSecretAccessKey &&
          !!this.sourceBucketPath &&
          !!this.sourceBucketName
        );
      }
      return false;
    },
  },
  watch: {
    active: {
      handler() {
        this.sourceAccessKeyId = '';
        this.sourceSecretAccessKey = '';
        this.sourceBucketPath = '';
        this.sourceBucketName = '';
      },
    },
  },
  methods: {
    toggleSource(i) {
      this.active = i;
    },
    nextStep() {
      this.$emit('next-step', {
        sourceUrl: this.sourceUrl,
        sourceProvider: this.items[this.active].sourceProvider,
        sourceBucketName: this.sourceBucketName,
        sourceBucketPath: this.sourceBucketPath,
        sourceAccessKeyId: this.sourceAccessKeyId,
        sourceSecretAccessKey: this.sourceSecretAccessKey,
      });
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 32px;
}
.ecs-button {
  float: right;
}
.ecs-video-insights__active-items {
  background: var(--cs-primary-lightest) !important;
  border: 1px solid #a2d5bf !important;
}
.ecs-video-insights__active-items i {
  color: var(--cs-gray-06);
}
.ecs-video-insights__non-active-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  height: 60px;
  border-radius: 3px;
  width: 100px;
  background: var(--cs-gray-01);
  color: var(--cs-gray-05);
  cursor: pointer;
  border: 1px solid var(--cs-gray-02);
}
.ecs-video-insights__non-active-items i {
  color: var(--cs-gray-03);
}
.ecs-video-insights__non-active-items img {
  width: 80px;
}
.ecs-video-insights__list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.ecs-video-insights__list > div > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ecs-video-insights__input-wrapper {
  background: var(--cs-gray-01);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
}
.ecs-video-insights__input-wrapper-inputs {
  width: 100%;
}
.ecs-video-insights__input-wrapper--secret-keys__inputs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.ecs-video-insights__settings {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ecs-video-insights__settings-input {
  width: 80%;
}
.ecs-video-insights__aws-img {
  padding-left: 5px;
  width: 40px !important;
}
</style>
