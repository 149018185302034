<template>
  <div>
    <cs-modal :show="showAdd" class="ecs-add-asset" @close="onClose">
      <div slot="header">Upload File</div>
      <div slot="body">
        <div class="mt-2">
          <cs-input
            v-model="name"
            label="File Name"
            placeholder="Project details.txt"
            required
            class="ecs-add-asset__input-wrapper-inputs"
          />
        </div>
        <div class="cs-textstyle-informative-paragraph-bold my-2">
          Select Source
        </div>
        <div class="ecs-add-asset__list">
          <div v-for="(item, index) in items" :key="index">
            <div
              :class="active === index ? 'ecs-add-asset__active-items' : ''"
              class="ecs-add-asset__non-active-items"
              @click="toggleSource(index, item)"
            >
              <div>
                <span v-if="!item.img">{{ item.text }}</span
                ><img
                  v-else
                  :src="item.img"
                  :class="item.text === 'aws' ? 'ecs-add-asset__aws-img' : ''"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="ecs-add-asset__input-wrapper my-2">
          <div v-if="active === 0">
            <cs-file-input
              file-type="*/*"
              upload-label="Upload File / Photo / Video"
              class="ecs-add-asset__input-wrapper-file-upload"
              :manual-upload="true"
              @selected="onUploadSelected"
            ></cs-file-input>
          </div>
          <div v-else-if="active === 1">
            <cs-input
              v-model="sourceUrl"
              label="Enter URL"
              class="ecs-add-asset__input-wrapper-inputs"
              required
            />
          </div>
          <div v-else class="ecs-add-asset__input-wrapper--secret-keys">
            <div class="ecs-add-asset__input-wrapper--secret-keys__inputs">
              <cs-input
                v-model="bucketUrl"
                label="Bucket Name"
                class="ecs-add-asset__input-wrapper-inputs"
                required
              />
              <cs-input
                v-model="bucketPath"
                label="Bucket Path"
                class="ecs-add-asset__input-wrapper-inputs"
                required
              />
            </div>
            <div class="ecs-add-asset__input-wrapper--secret-keys__inputs">
              <cs-input
                v-model="accessKeyId"
                label="Access Key ID"
                class="ecs-add-asset__input-wrapper-inputs"
                required
              />
              <cs-input
                v-model="secretAccessKey"
                label="Secret Access Key"
                class="ecs-add-asset__input-wrapper-inputs"
                required
              />
            </div>
          </div>
        </div>
        <div
          class="ecs-add-asset__tags-container my-2"
          @keydown.enter="onAddTag"
        >
          <cs-input
            v-model="tag_data"
            placeholder="ex: 'Owner = Janakan'"
            label="Tags"
          />
          <cs-button
            icon="cs-icons-add-filled"
            variant="primary"
            fill="outline"
            @click="onAddTag"
            >&nbsp;Add tag</cs-button
          >
          <div
            v-if="tags"
            class="cs-textstyle-paragraph-small ecs-add-asset__tags"
          >
            <div
              v-for="tag in tags"
              :key="tag.key"
              class="ecs-add-asset__tags--tag"
            >
              <cs-tag>{{ tag.key | formatTag }}</cs-tag>
              <cs-tag
                :style="{
                  '--cs-tag-color': '#87939B',
                  '--cs-tag-texcolor': '#ffffff',
                }"
                >{{ tag.value | formatTag }}&nbsp;<i
                  class="cs-icons-close"
                  @click="onRemoveTag(tag.key)"
                ></i
              ></cs-tag>
            </div>
          </div>
        </div>
      </div>
      <cs-button
        slot="main"
        variant="primary"
        :disabled="!valid || isDisabled"
        @click="uploadAsset"
        >Upload File</cs-button
      >
    </cs-modal>
  </div>
</template>

<script>
import axios from 'axios';
import CreateMediaLibraryAsset from '@/gql/medialibrary/CreateMediaLibraryAsset.gql';
const awsImgUrl = require('@/assets/images/AWS.svg');
const gcpImgUrl = require('@/assets/images/gcp.svg');
const azureImgUrl = require('@/assets/images/Azure.svg');
const doImgUrl = require('@/assets/images/do.svg');
export default {
  components: {},
  props: {
    showAdd: {
      type: Boolean,
      required: true,
    },
    mediaLibraryId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      destinationUrl: '',
      isSaving: false,
      active: 0,
      items: [
        {
          text: 'Upload',
          path: 'upload',
          img: null,
        },
        {
          text: 'URL',
          path: 'url',
          img: null,
        },
        {
          text: 'aws',
          path: 'aws',
          img: awsImgUrl,
        },
        {
          text: 'gcp',
          path: 'gcp',
          img: gcpImgUrl,
        },
        {
          text: 'Digital Ocean',
          path: 'digitalocean',
          img: doImgUrl,
        },
        {
          text: 'azure',
          path: 'azure',
          img: azureImgUrl,
        },
      ],
      sourceUrl: null,
      bucketUrl: null,
      bucketPath: null,
      accessKeyId: null,
      secretAccessKey: null,
      tag_data: '',
      tags: [],
      uploadFileData: null,
    };
  },
  computed: {
    isDisabled() {
      return this.isSaving;
    },
    sourceProvider() {
      if (this.active === 0) {
        return 'Upload';
      }
      if (this.active === 1) {
        return 'URL';
      }
      return this.items[this.active].text.toUpperCase();
    },
    valid() {
      return this.name;
    },
  },
  methods: {
    onAddTag() {
      if (!this.tag_data) return;
      const [key, value] = this.tag_data.split('=').map((v) => v.trim());
      if (!key || !value) return;
      if (this.tags.find((tag) => tag.key === key)) {
        this.tag_data = '';
        return;
      }
      this.tags.push({
        key,
        value,
      });
      this.tag_data = '';
    },
    onRemoveTag(key) {
      this.tags = this.tags.filter((tag) => tag.key !== key);
    },
    formatTags(tags) {
      if (!tags.length) {
        return [];
      }
      const tagsData = [];
      tags.forEach((tag) => tagsData.push(`${tag.key}=${tag.value}`));
      return tagsData;
    },
    onClose() {
      this.$emit('close-add-asset-modal');
    },
    updateList() {
      this.$emit('created-asset');
      this.onClose();
    },
    toggleSource(i) {
      this.active = i;
    },
    onUploadSelected(e) {
      this.uploadFileData = e;
    },
    uploadAssetApi(file) {
      const formData = new FormData();
      formData.append('media_library_id', this.mediaLibraryId);
      formData.append('tags', this.formatTags(this.tags));
      formData.append('name', this.name);
      formData.append('file', file, file.name);
      const headers = {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.access_token}`,
      };
      this.isSaving = true;
      axios
        .post(`${process.env.VUE_APP_REST_ENDPOINT}/media/upload`, formData, {
          headers,
        })
        .then(() => {
          this.isSaving = false;
          this.$emit('created-asset');
          this.$emit('close-add-asset-modal');
        })
        .catch((err) => {
          this.isSaving = false;
          this.$toast.open({
            message: `${err.message}`,
            type: 'error',
            duration: 3000,
            dismissible: true,
            position: 'top',
          });
          this.$emit('close-add-asset-modal');
        });
    },
    async uploadAsset() {
      this.isSaving = true;
      if (this.sourceProvider === 'Upload') {
        this.uploadAssetApi(this.uploadFileData);
        return;
      }
      await this.$apollo.mutate({
        mutation: CreateMediaLibraryAsset,
        variables: {
          sourceProvider: this.sourceProvider,
          name: this.name,
          mediaLibraryId: this.mediaLibraryId,
          tags: this.formatTags(this.tags),
          sourceUrl: this.sourceUrl ? this.sourceUrl : '',
          sourceBucketPath: this.bucketPath,
          sourceBucketName: this.bucketUrl,
          sourceAccessKey: this.accessKeyId,
          sourceSecretKey: this.secretAccessKey,
        },
      });
      this.isSaving = false;
      this.$emit('created-asset');
      this.$emit('close-add-asset-modal');
    },
  },
};
</script>

<style scoped>
.ecs-add-asset {
  ---cs-modal-width: 900px !important;
}
.ecs-add-asset__input-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ecs-add-asset__input-wrapper-inputs {
  width: 100%;
}

.ecs-add-asset__input-wrapper-file-upload >>> label {
  width: 100%;
}

.ecs-add-asset__active-items {
  background: var(--cs-primary-lightest) !important;
  border: 1px solid #a2d5bf !important;
}
.ecs-add-asset__active-items i {
  color: var(--cs-gray-06);
}
.ecs-add-asset__non-active-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  height: 60px;
  border-radius: 3px;
  width: 113px;
  background: var(--cs-gray-01);
  color: var(--cs-gray-05);
  cursor: pointer;
  border: 1px solid var(--cs-gray-02);
}
.ecs-add-asset__non-active-items i {
  color: var(--cs-gray-03);
}
.ecs-add-asset__non-active-items img {
  width: 80px;
}
.ecs-add-asset__list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 7px;
}
.ecs-add-asset__list {
  scrollbar-width: thin;
  scrollbar-color: var(--cs-gray-02) var(--cs-gray-00);
}

.ecs-add-asset__list::-webkit-scrollbar {
  height: 10px;
}

.ecs-add-asset__list::-webkit-scrollbar-track {
  background: var(--cs-gray-00);
}

.ecs-add-asset__list::-webkit-scrollbar-thumb {
  background-color: var(--cs-gray-02);
  border-radius: 20px;
}
.ecs-add-asset__list::-webkit-scrollbar-thumb:hover {
  background-color: var(--cs-gray-03);
}
.ecs-add-asset__list > div > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ecs-add-asset__input-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ecs-add-asset__input-wrapper-inputs {
  width: 100%;
}
.ecs-add-asset__input-wrapper--secret-keys__inputs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.ecs-add-asset__settings {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ecs-add-asset__settings-input {
  width: 80%;
}
.ecs-add-asset__aws-img {
  padding-left: 5px;
  width: 40px !important;
}

.ecs-add-asset__tags-container {
  margin: 20px 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}
.ecs-add-asset__tags-container .cs-button {
  position: absolute;
  right: 20px;
  top: 35px;
}
.ecs-add-asset__tags {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
.ecs-add-asset__tags--tag {
  display: flex;
  gap: 1px;
}

.ecs-add-asset__tags--tag >>> .cs-tag:first-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ecs-add-asset__tags--tag >>> .cs-tag:last-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
@media (max-width: 767px) {
  .ecs-add-asset__tags-container .cs-button {
    top: 30px;
  }
}
</style>
