<template>
  <div>
    <div class="ecs-modal-description cs-textstyle-paragraph-small">
      Select the features you want to analyze for the video.
    </div>
    <div class="cs-textstyle-detail-heading my-2">Features</div>
    <cs-checkbox
      v-for="(feature, index) in features"
      :key="feature.label"
      class="my-2"
      :label="feature.label"
      :value="feature.value"
      @input="onInput($event, index)"
    ></cs-checkbox>
    <cs-button
      class="ecs-button"
      :disabled="!selectedFeatures.length"
      @click="nextStep"
    >
      Continue
    </cs-button>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      features: [
        {
          label: 'Content Moderation',
          value: false,
        },
        {
          label: 'Optical Character Recognition (OCR)',
          value: false,
        },
        {
          label: 'Object Detection and Recognition',
          value: false,
        },
        {
          label: 'Tagging',
          value: false,
        },
        {
          label: 'Metadata',
          value: false,
        },
      ],
    };
  },
  computed: {
    selectedFeatures() {
      return this.formatData(this.features);
    },
  },
  methods: {
    nextStep() {
      this.$emit('next-step', {
        features: this.selectedFeatures,
      });
    },
    onInput(checked, index) {
      this.features[index].value = checked;
    },
    formatData(features) {
      const filteredData = features.filter(
        (feature) => feature.value && feature
      );
      return filteredData.map((feature) =>
        feature.label.toLowerCase().split(' ').join('-')
      );
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 20px;
}
.ecs-button {
  float: right;
}
</style>
