<template>
  <div class="d-flex flex-column">
    <div v-if="showDesinationUrl" class="d-flex flex-column">
      <div class="ecs-modal-description cs-textstyle-paragraph-small">
        Connect your destination cloud provider where you want to upload your
        output file.
      </div>
      <div class="cs-textstyle-informative-paragraph-bold my-2">
        Select Destination
      </div>
      <div class="ecs-image-insights__list">
        <div v-for="(item, index) in items" :key="index">
          <div
            :class="active === index ? 'ecs-image-insights__active-items' : ''"
            class="ecs-image-insights__non-active-items"
            @click="toggleSource(index, item)"
          >
            <div>
              <span v-if="!item.img">{{ item.text }}</span
              ><img
                v-else
                :src="item.img"
                :class="
                  item.text === 'aws' ? 'ecs-image-insights__aws-img' : ''
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="ecs-image-insights__input-wrapper my-2">
        <div class="ecs-image-insights__input-wrapper--secret-keys">
          <div class="ecs-image-insights__input-wrapper--secret-keys__inputs">
            <cs-input
              v-model="bucketUrl"
              label="Bucket Name"
              class="ecs-image-insights__input-wrapper-inputs"
              required
            />
            <cs-input
              v-model="bucketPath"
              label="Bucket Path"
              class="ecs-image-insights__input-wrapper-inputs"
              required
            />
          </div>
          <div class="ecs-image-insights__input-wrapper--secret-keys__inputs">
            <cs-input
              v-model="accessKeyId"
              label="Access Key ID"
              class="ecs-image-insights__input-wrapper-inputs"
              required
            />
            <cs-input
              v-model="secretAccessKey"
              label="Secret Access Key"
              class="ecs-image-insights__input-wrapper-inputs"
              required
            />
          </div>
        </div>
      </div>
      <cs-input
        v-model="webhookUrl"
        label="Webhook Url (Optional)"
        class="ecs-caption__input-wrapper-inputs mb-3"
      />
      <cs-button
        class="d-flex align-self-end"
        :disabled="!valid"
        @click="analyzeImage"
      >
        Analyze
      </cs-button>
    </div>
    <div v-else>
      <div class="ecs-modal-description cs-textstyle-paragraph-small">
        Click on the button to analyze your image.
      </div>
      <cs-button
        class="ecs-button"
        :disabled="isDisabled"
        @click="analyzeDestionationlessImage"
      >
        Analyze
      </cs-button>
    </div>
  </div>
</template>

<script>
import CreateImageInsight from '@/gql/imageinsight/CreateImageInsight.gql';
const awsImgUrl = require('@/assets/images/AWS.svg');
const gcpImgUrl = require('@/assets/images/gcp.svg');
const azureImgUrl = require('@/assets/images/Azure.svg');
const doImgUrl = require('@/assets/images/do.svg');
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      destinationUrl: '',
      isSaving: false,
      active: 0,
      items: [
        {
          text: 'aws',
          path: 'aws',
          img: awsImgUrl,
        },
        {
          text: 'gcp',
          path: 'gcp',
          img: gcpImgUrl,
        },
        {
          text: 'Digital Ocean',
          path: 'digitalocean',
          img: doImgUrl,
        },
        {
          text: 'azure',
          path: 'azure',
          img: azureImgUrl,
        },
      ],
      sourceUrl: null,
      bucketUrl: null,
      accessKeyId: null,
      secretAccessKey: null,
      bucketPath: null,
      webhookUrl: null,
    };
  },
  computed: {
    isDisabled() {
      return this.isSaving;
    },
    showDesinationUrl() {
      const { features } = this.data;
      return features.includes('object-detection');
    },
    valid() {
      return (
        !!this.accessKeyId &&
        !!this.secretAccessKey &&
        !!this.bucketUrl &&
        !!this.bucketPath
      );
    },
  },
  methods: {
    toggleSource(i) {
      this.active = i;
    },
    async analyzeDestionationlessImage() {
      this.isSaving = true;
      if (this.data.sourceProvider === 'URL') {
        await this.$apollo.mutate({
          mutation: CreateImageInsight,
          variables: {
            sourceProvider: 'URL',
            projectId: this.$route.params.id,
            sourceUrl: this.data.sourceUrl ? this.data.sourceUrl : '',
            webhookUrl: this.webhookUrl ? this.webhookUrl : '',
            features: this.data.features,
          },
        });
      } else {
        await this.$apollo.mutate({
          mutation: CreateImageInsight,
          variables: {
            sourceProvider: this.data.sourceProvider,
            projectId: this.$route.params.id,
            sourceBucketPath: this.data.sourceBucketPath,
            sourceBucketName: this.data.sourceBucketUrl,
            sourceAccessKey: this.data.sourceAccessKeyId,
            sourceSecretKey: this.data.sourceSecretAccessKey,
            webhookUrl: this.webhookUrl ? this.webhookUrl : '',
            features: this.data.features,
          },
        });
      }
      this.isSaving = false;
      this.$emit('close-modal');
    },
    async analyzeImage() {
      this.isSaving = true;
      if (this.data.sourceProvider === 'URL') {
        await this.$apollo.mutate({
          mutation: CreateImageInsight,
          variables: {
            destinationProvider: this.items[this.active].path,
            sourceProvider: this.data.sourceProvider,
            projectId: this.$route.params.id,
            sourceUrl: this.data.sourceUrl ? this.data.sourceUrl : '',
            destinationAccessKey: this.accessKeyId,
            destinationBucketName: this.bucketUrl,
            destinationBucketPath: this.bucketPath,
            destinationSecretKey: this.secretAccessKey,
            webhookUrl: this.webhookUrl ? this.webhookUrl : '',
            features: this.data.features,
          },
        });
      } else {
        await this.$apollo.mutate({
          mutation: CreateImageInsight,
          variables: {
            destinationProvider: this.items[this.active].path,
            sourceProvider: this.data.sourceProvider,
            projectId: this.$route.params.id,
            sourceBucketPath: this.data.sourceBucketPath,
            sourceBucketName: this.data.sourceBucketUrl,
            sourceAccessKey: this.data.sourceAccessKeyId,
            sourceSecretKey: this.data.sourceSecretAccessKey,
            destinationAccessKey: this.accessKeyId,
            destinationBucketName: this.bucketUrl,
            destinationBucketPath: this.bucketPath,
            destinationSecretKey: this.secretAccessKey,
            webhookUrl: this.webhookUrl ? this.webhookUrl : '',
            features: this.data.features,
          },
        });
      }
      this.isSaving = false;
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 16px;
}
.ecs-image-insights__input-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ecs-image-insights__input-wrapper-inputs {
  width: 100%;
}

.ecs-image-insights__active-items {
  background: var(--cs-primary-lightest) !important;
  border: 1px solid #a2d5bf !important;
}
.ecs-image-insights__active-items i {
  color: var(--cs-gray-06);
}
.ecs-image-insights__non-active-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  height: 60px;
  border-radius: 3px;
  width: 113px;
  background: var(--cs-gray-01);
  color: var(--cs-gray-05);
  cursor: pointer;
  border: 1px solid var(--cs-gray-02);
}
.ecs-image-insights__non-active-items i {
  color: var(--cs-gray-03);
}
.ecs-image-insights__non-active-items img {
  width: 80px;
}
.ecs-image-insights__list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 7px;
}
.ecs-image-insights__list {
  scrollbar-width: thin;
  scrollbar-color: var(--cs-gray-02) var(--cs-gray-00);
}

.ecs-image-insights__list::-webkit-scrollbar {
  height: 10px;
}

.ecs-image-insights__list::-webkit-scrollbar-track {
  background: var(--cs-gray-00);
}

.ecs-image-insights__list::-webkit-scrollbar-thumb {
  background-color: var(--cs-gray-02);
  border-radius: 20px;
}
.ecs-image-insights__list::-webkit-scrollbar-thumb:hover {
  background-color: var(--cs-gray-03);
}
.ecs-image-insights__list > div > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ecs-image-insights__input-wrapper {
  background: var(--cs-gray-01);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
}
.ecs-image-insights__input-wrapper-inputs {
  width: 100%;
}
.ecs-image-insights__input-wrapper--secret-keys__inputs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.ecs-image-insights__settings {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ecs-image-insights__settings-input {
  width: 80%;
}
.ecs-image-insights__aws-img {
  padding-left: 5px;
  width: 40px !important;
}
</style>
