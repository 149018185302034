<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Transcoding / <span>All Jobs</span>
      </div>
      <div class="ecs-dashboard__header-button">
        <cs-button :disabled="disable" variant="primary" @click="showAddJob"
          >New Job</cs-button
        >
      </div>
    </div>
    <div
      v-if="transcoding_jobs && transcoding_jobs.length"
      class="ecs-transcode__search"
    >
      <cs-search-bar
        v-model="val"
        class="ecs-transcode__search-bar"
        placeholder="Search Jobs"
      />
      <i
        class="cs-icons-excel ecs-transcode__search-bar-icon ecs-cursor"
        @click="downloadFile"
      ></i>
    </div>
    <!-- Empty State -->
    <div v-if="transcoding_jobs && !transcoding_jobs.length">
      <app-empty-state
        title="No transcoding jobs"
        description="You have not created any transcoding job yet"
        button-text="Add New Job"
        :disable="disable"
        @add-action="showAddJob"
      />
    </div>
    <!-- Spinner -->
    <div
      v-if="$apollo.queries.transcoding_jobs.loading"
      class="ecs-spinner__wrapper"
    >
      <cs-spinner class="ecs-spinner" />
    </div>
    <div
      v-if="transcoding_jobs && transcoding_jobs.length"
      class="ecs-dashboard__table-head"
    >
      <b-table
        :items="transcoding_jobs"
        :fields="fields"
        :current-page="currentPage"
        borderless
        hover
        striped
        :filter="filteredResult"
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        :busy="$apollo.queries.transcoding_jobs.loading"
        @row-clicked="myRowClickHandler"
      >
        <template #table-busy>
          <div class="ecs-dashboard__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <!-- Job ID -->
        <template #cell(id)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text slot="visible" :long-text="data.item.id" />
            <div slot="popover">{{ data.item.id }}</div>
          </app-hover>
        </template>
        <!-- Source url -->
        <template #cell(source_url)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text
              slot="visible"
              :long-text="data.item.source_url"
            />
            <div slot="popover">{{ data.item.source_url }}</div>
          </app-hover>
        </template>
        <!-- Destination url -->
        <template #cell(destination_url)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text
              slot="visible"
              :long-text="getDestinationUrl(data.item)"
            />
            <div slot="popover">
              {{ getDestinationUrl(data.item) }}
            </div>
          </app-hover>
        </template>
        <!-- Start time -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | moment('D MMM YYYY, h:mm a') }}
        </template>
        <!-- Start time -->
        <template #cell(output_format)="data">
          {{ data.item.output_formats[0].toUpperCase() }}
        </template>
        <!-- chargeable time -->
        <template #cell(chargeable_time)="data">
          {{
            data.item.chargeable_time ? `${data.item.chargeable_time}s` : '-'
          }}
        </template>
        <!-- Status -->
        <template #cell(status)="data">
          <cs-tag
            :class="['ecs-transcode__transcode-tag--' + data.item.status]"
            >{{ data.item.status }}</cs-tag
          >
        </template>
      </b-table>
    </div>
    <!-- Pagination -->
    <div class="ecs-pagination">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      ></b-pagination>
    </div>
    <!-- Add New Job Modal  -->
    <app-add-transcoding-job-modal
      :show-add="showNew"
      @close-add-transcoding-job-modal="closeAdd"
      @update-transcoding-jobs="closeAfterJobCreated"
    />
  </div>
</template>

<script>
import ListTranscodingJobs from '@/gql/transcoding/ListTranscodingJobs.gql';
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';
import $excel from '@/services/excel';
// Components
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppAddTranscodingJobModal from '@/components/transcoding/AddTranscodingJobModal.vue';
import AppHover from '@/components/general/Hover.vue';

export default {
  components: {
    AppEmptyState,
    AppTruncatedText,
    AppAddTranscodingJobModal,
    AppHover,
  },
  data() {
    return {
      rows: '',
      perPage: 10,
      currentPage: 1,
      disable: null,
      val: '',
      projectId: this.$route.params.id,
      showNew: false,
      fields: [
        {
          key: 'id',
          label: 'Job Id',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'source_url',
          label: 'Origin',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'destination_url',
          label: 'Destination',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Time',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'output_format',
          label: 'Output Setting',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'chargeable_time',
          label: 'Execution',
          thClass: 'ecs-dashboard__table-head',
        },
      ],
    };
  },
  apollo: {
    transcoding_jobs: {
      query: ListTranscodingJobs,
      variables() {
        return {
          projectId: this.projectId,
          offset: 0,
          limit: 10,
        };
      },
      result(data) {
        const count = data?.data?.transcoding_jobs[0]?.total_number_of_jobs;
        this.rows = count - 10;
      },
    },
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  watch: {
    currentPage: {
      handler(value) {
        this.$apollo.queries.transcoding_jobs.refetch({
          offset: value * this.perPage - 1,
        });
      },
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    showAddJob() {
      this.showNew = true;
    },
    closeAdd() {
      this.showNew = false;
    },
    myRowClickHandler(record) {
      this.$router.push({
        name: 'JobDetail',
        params: { jobId: record.id },
      });
    },
    async closeAfterJobCreated() {
      this.showNew = false;
      await this.$apollo.queries.transcoding_jobs.refetch();
    },
    getDestinationUrl(job) {
      if (job.output_formats[0] === 'MP4') {
        return job.mp4_url;
      }
      if (job.output_formats[0] === 'HLS') {
        return job.hls_url;
      }
      if (job.output_formats[0] === 'MP3') {
        return job.mp3_url;
      }
      return job.thumbnail_url;
    },

    // Download job list into an excel file
    downloadFile() {
      const fields = [
        'id',
        'created_at',
        'status',
        'source_url',
        'destination_url',
        'output_format',
        'chargeable_time',
      ];

      // $csv.export(output, fields, 'file name');
      $excel.export(this.transcoding_jobs, fields, 'Transcoding Jobs');
    },
  },
};
</script>

<style scoped>
.ecs-transcode__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0px 20px 0px;
}
.ecs-transcode__search-bar {
  width: 100%;
}
.ecs-transcode__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}
.ecs-transcode__transcode-tag--QUEUED {
  --cs-tag-color: var(--cs-info-base) !important;
}
.ecs-transcode__transcode-tag--IN {
  --cs-tag-color: var(--cs-warning-base) !important;
}
.ecs-transcode__transcode-tag--FAILED {
  --cs-tag-color: var(--cs-danger-base) !important;
}
.ecs-transcode__transcode-tag--COMPLETED {
  --cs-tag-color: var(--cs-primary-base) !important;
}
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.ecs-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  justify-content: flex-end;
}
.ecs-pagination >>> .page-link {
  color: var(--cs-gray-07);
  box-shadow: none;
}
.ecs-pagination >>> .page-item.active .page-link {
  background-color: transparent;
  border-color: #dee2e6;
  color: var(--cs-primary-base);
}
</style>
