<template>
  <div class="ecs-dashboard">
    <!-- Header -->
    <div v-if="transcoding_job" class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Transcoding / <span>Job {{ transcoding_job.id }}</span>
      </div>
    </div>
    <div v-if="transcoding_job" class="ecs-transcoding-detail__container">
      <div class="ecs-transcoding-detail__info">
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Job Id</p>
          <div class="transcoding-job__id">
            <app-truncated-text
              :max-chars="15"
              :long-text="transcoding_job.id"
            />
            <i
              class="cs-icons-copy copy-icon"
              @click="copyText(transcoding_job.id)"
            ></i>
          </div>
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Job Status</p>
          <cs-tag
            :class="['ecs-transcode__transcode-tag--' + transcoding_job.status]"
            >{{ transcoding_job.status || 'QUEUED' }}</cs-tag
          >
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Execution Time</p>
          <p class="cs-textstyle-paragraph">
            {{
              transcoding_job.chargeable_time
                ? `${transcoding_job.chargeable_time}s`
                : '-'
            }}
          </p>
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Cost</p>
          <p class="cs-textstyle-paragraph">
            ${{ transcoding_job.cost ? transcoding_job.cost : '0' }}
          </p>
        </div>
        <div>
          <p class="cs-textstyle-paragraph-small-bold">Started</p>
          <p class="cs-textstyle-paragraph">
            {{ transcoding_job.created_at | moment('D MMM YYYY, h:mm a') }}
          </p>
        </div>
      </div>
      <div>
        <app-step-progress-bar
          :steps="mySteps"
          :current-step="currentStep"
          icon-class="cs-icons-check"
          passive-color="#E6EAEA"
          active-color="#00C472"
          :active-thickness="3"
          :passive-thickness="3"
          :line-thickness="3"
        />
      </div>
      <app-info-detail-card
        label="Input File"
        :format="transcoding_job.source_format || ''"
        :source="transcoding_job.source_url"
        :size="sourceFileSize"
      />
      <app-info-detail-card
        label="Output File"
        :format="transcoding_job.output_formats[0]"
        :destination="getDestinationUrl(transcoding_job)"
        :size="convertedSize"
      />
    </div>
  </div>
</template>

<script>
import AppInfoDetailCard from '@/components/general/InfoDetailCard.vue';
import AppStepProgressBar from '@/components/general/StepProgressBar.vue';
import GetTranscodingJob from '@/gql/transcoding/GetTranscodingJob.gql';
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import { formatBytes } from '@/services/utils';

export default {
  components: {
    AppStepProgressBar,
    AppTruncatedText,
    AppInfoDetailCard,
  },
  apollo: {
    transcoding_job: {
      query: GetTranscodingJob,
      variables() {
        return {
          getTranscodingJobId: this.$route.params.jobId,
        };
      },
    },
  },
  data() {
    return {
      mySteps: ['CREATED', 'DOWNLOADED', 'TRANSCODED', 'COMPLETED'],
    };
  },
  computed: {
    currentStep() {
      if (this.transcoding_job && this.transcoding_job.milestones.length) {
        const latestStatus = this.transcoding_job.milestones[0].status;
        return this.mySteps.indexOf(latestStatus) + 1;
      }
      return 0;
    },
    sourceFileSize() {
      const fileSize = this.transcoding_job.source_file_size
        ? formatBytes(this.transcoding_job.source_file_size)
        : '-';
      return fileSize;
    },
    convertedSize() {
      if (this.transcoding_job.output_formats[0] === 'MP4') {
        const fileSize = this.transcoding_job.mp4_file_size
          ? formatBytes(this.transcoding_job.mp4_file_size)
          : '-';
        return fileSize;
      }
      if (this.transcoding_job.output_formats[0] === 'HLS') {
        const fileSize = this.transcoding_job.hls_file_size
          ? formatBytes(this.transcoding_job.hls_file_size)
          : '-';
        return fileSize;
      }
      if (this.transcoding_job.output_formats[0] === 'MP3') {
        const fileSize = this.transcoding_job.mp3_file_size
          ? formatBytes(this.transcoding_job.mp3_file_size)
          : '-';
        return fileSize;
      }
      const fileSize = this.transcoding_job.thumbnail_file_size
        ? formatBytes(this.transcoding_job.thumbnail_file_size)
        : '-';
      return fileSize;
    },
  },
  methods: {
    async copyText(id) {
      await navigator.clipboard.writeText(id);
      this.$toast.open({
        message: 'ID copied',
        type: 'success',
        duration: 2000,
        dismissible: true,
        position: 'top',
      });
    },
    getDestinationUrl(job) {
      if (job.output_formats[0] === 'MP4') {
        return job.mp4_url;
      }
      if (job.output_formats[0] === 'HLS') {
        return job.hls_url;
      }
      if (job.output_formats[0] === 'MP3') {
        return job.mp3_url;
      }
      return job.thumbnail_url;
    },
  },
};
</script>

<style scoped>
.ecs-transcoding-detail__container {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 40px;
  padding: 32px;
  gap: 50px;
}
.ecs-transcoding-detail__info {
  display: flex;
  justify-content: space-between;
  color: var(--cs-gray-05);
}
.ecs-transcoding-detail__info p {
  margin-bottom: 5px;
}
.ecs-transcode__transcode-tag--QUEUED {
  --cs-tag-color: var(--cs-info-base) !important;
}
.ecs-transcode__transcode-tag--IN {
  --cs-tag-color: var(--cs-warning-base) !important;
}
.ecs-transcode__transcode-tag--FAILED {
  --cs-tag-color: var(--cs-danger-base) !important;
}
.ecs-transcode__transcode-tag--COMPLETED {
  --cs-tag-color: var(--cs-primary-base) !important;
}
.copy-icon {
  color: var(--cs-primary-base);
  font-size: 22px;
  cursor: pointer;
}
.transcoding-job__id {
  display: flex;
  align-items: center;
}
</style>
