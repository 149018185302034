<template>
  <div class="ecs-dashboard">
    <div class="ecs-dashboard__header">
      <div class="ecs-dashboard__header-title">
        Captions Service / <span>History</span>
      </div>
      <div class="ecs-dashboard__header-button">
        <cs-button variant="primary" :disabled="disable" @click="showAddJob"
          >New Job</cs-button
        >
      </div>
    </div>

    <div v-if="jobs && jobs.length" class="ecs-captions__search">
      <cs-search-bar
        v-model="val"
        class="ecs-captions__search-bar"
        placeholder="Search Captions"
      />
      <i
        class="cs-icons-excel ecs-captions__search-bar-icon ecs-cursor"
        @click="downloadFile"
      ></i>
    </div>

    <!-- Empty State -->
    <div v-if="jobs && !jobs.length">
      <app-empty-state
        title="No caption service jobs"
        description="You have not created any caption service job yet"
        button-text="Add New Job"
        :disable="disable"
        @add-action="showAddJob"
      />
    </div>
    <!-- Spinner -->
    <div v-if="$apollo.queries.jobs.loading" class="ecs-spinner__wrapper">
      <cs-spinner class="ecs-spinner" />
    </div>
    <!-- Table  -->
    <div v-if="jobs && jobs.length" class="ecs-dashboard__table-head">
      <b-table
        :items="jobs"
        :fields="fields"
        borderless
        hover
        :current-page="currentPage"
        striped
        :filter="filteredResult"
        table-variant="light"
        tbody-tr-class="ecs-dashboard__table-row"
        @row-clicked="myRowClickHandler"
      >
        <template #table-busy>
          <div class="ecs-dashboard__table-busy">
            <cs-spinner class="ecs-spinner" />
          </div>
        </template>
        <!-- Job ID -->
        <template #cell(id)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text slot="visible" :long-text="data.item.id" />
            <div slot="popover">{{ data.item.id }}</div>
          </app-hover>
        </template>
        <!-- Source url -->
        <template #cell(source_url)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text
              slot="visible"
              :long-text="data.item.source_url"
            />
            <div slot="popover">{{ data.item.source_url }}</div>
          </app-hover>
        </template>
        <!-- Destination url -->
        <template #cell(destination_url)="data">
          <app-hover :index="`${data.index}${data.field.key}`">
            <app-truncated-text
              slot="visible"
              :long-text="data.item.destination_url"
            />
            <div slot="popover">{{ data.item.destination_url }}</div>
          </app-hover>
        </template>
        <!-- Start time -->
        <template #cell(created_at)="data">
          {{ data.item.created_at | moment('D MMM YYYY, h:mm a') }}
        </template>
        <!-- End time -->
        <template #cell(updated_at)="data">
          {{ data.item.updated_at | moment('D MMM YYYY, h:mm a') }}
        </template>
        <!-- Status -->
        <template #cell(status)="data">
          <cs-tag :class="['ecs-captions--' + data.item.status]">{{
            data.item.status
          }}</cs-tag>
        </template>
        <!-- Execution time  -->
        <template #cell(chargeable_time)="data">
          {{ data.item.chargeable_time
          }}<span v-if="data.item.chargeable_time > 0">s</span>
        </template>
      </b-table>
    </div>
    <div class="ecs-pagination">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
      ></b-pagination>
    </div>
    <!-- Add New Job Modal  -->
    <app-new-job-modal
      :show-add="showNew"
      project-id="abc"
      @close-new-job-modal="closeAdd"
      @update-caption-jobs-history="updateJobsHistory"
    />
  </div>
</template>

<script>
import AppTruncatedText from '@/components/general/TruncatedText.vue';
import AppEmptyState from '@/components/general/EmptyState.vue';
import AppNewJobModal from '@/components/caption/NewJobModal.vue';
import AppHover from '@/components/general/Hover.vue';
import ListCaptionJobs from '@/gql/caption/ListCaptionJobs.gql';
import { getAccessLevel } from '@/services/utils';
import $bus from '@/services/bus';
import $excel from '@/services/excel';

export default {
  components: {
    AppTruncatedText,
    AppEmptyState,
    AppNewJobModal,
    AppHover,
  },
  apollo: {
    jobs: {
      query: ListCaptionJobs,
      variables() {
        return {
          projectId: this.$route.params.id,
          offset: 0,
          limit: 10,
        };
      },
      result(data) {
        const count = data?.data?.jobs[0]?.total_number_of_jobs;
        this.rows = count - 10;
      },
    },
  },
  data() {
    return {
      disable: null,
      rows: '',
      perPage: 10,
      currentPage: 1,
      val: '',
      fields: [
        {
          key: 'id',
          label: 'ID',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'status',
          label: 'Status',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'source_url',
          label: 'Origin',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'destination_url',
          label: 'Destination',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Start time',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'updated_at',
          label: 'End time',
          thClass: 'ecs-dashboard__table-head',
        },
        {
          key: 'chargeable_time',
          label: 'Execution',
          thClass: 'ecs-dashboard__table-head',
        },
      ],
      showNew: false,
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  watch: {
    currentPage: {
      handler(value) {
        this.$apollo.queries.jobs.refetch({
          offset: value * this.perPage - 1,
        });
      },
    },
  },
  mounted() {
    const role = getAccessLevel();
    this.accessControl(role);
    $bus.$on('access-level-updated', () => {
      const accessLevel = getAccessLevel();
      this.accessControl(accessLevel);
    });
  },
  methods: {
    // Download job list into an excel file
    downloadFile() {
      const fields = [
        'id',
        'created_at',
        'updated_at',
        'status',
        'source_url',
        'destination_url',
        'chargeable_time',
      ];

      // $csv.export(output, fields, 'file name');
      $excel.export(this.jobs, fields, 'Caption Jobs');
    },
    // Access Control
    accessControl(accessLevel) {
      this.role = accessLevel;
      if (accessLevel === 'VIEWER') {
        this.disable = true;
      } else {
        this.disable = false;
      }
    },
    showAddJob() {
      this.showNew = true;
    },
    updateJobsHistory() {
      this.showNew = false;
      this.$apollo.queries.jobs.refetch();
    },
    closeAdd() {
      this.showNew = false;
    },
    myRowClickHandler(record) {
      this.$router.push({
        name: 'CaptionsJobDetail',
        params: { jobId: record.id },
      });
    },
  },
};
</script>

<style scoped>
.ecs-captions__search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 40px 0px 20px 0px;
}
.ecs-captions__search-bar {
  width: 100%;
}
.ecs-captions__search-bar-icon {
  margin-left: 20px;
  font-size: 28px;
}
.ecs-captions--QUEUED {
  --cs-tag-color: var(--cs-info-base) !important;
}
.ecs-captions--FAILED {
  --cs-tag-color: var(--cs-danger-base) !important;
}
.ecs-captions--IN {
  --cs-tag-color: var(--cs-warning-base) !important;
}
.ecs-captions--COMPLETED {
  --cs-tag-color: var(--cs-success-base) !important;
}
.ecs-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.ecs-spinner__wrapper .ecs-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.ecs-pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  justify-content: flex-end;
}
.ecs-pagination >>> .page-link {
  color: var(--cs-gray-07);
  box-shadow: none;
}
.ecs-pagination >>> .page-item.active .page-link {
  background-color: transparent;
  border-color: #dee2e6;
  color: var(--cs-primary-base);
}
</style>
