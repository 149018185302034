<!-- Made destination as required since destination provider is required in the backend -->
<template>
  <div>
    <div class="ecs-image-insights__input-wrapper">
      <div class="ecs-modal-description cs-textstyle-paragraph-small">
        Connect your destination cloud provider where you want to upload your
        output file.
      </div>
      <div class="cs-textstyle-detail-heading my-2">
        Select Destination <span class="light-txt">(Optional)</span>
      </div>
      <div class="ecs-video-insights__list">
        <div v-for="(item, index) in items" :key="index">
          <div
            :class="active === index ? 'ecs-video-insights__active-items' : ''"
            class="ecs-video-insights__non-active-items"
            @click="toggleSource(index, item)"
          >
            <div>
              <span v-if="!item.img">{{ item.text }}</span
              ><img
                v-else
                :src="item.img"
                :class="
                  item.text === 'AWS' ? 'ecs-video-insights__aws-img' : ''
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="ecs-video-insights__input-wrapper my-2">
        <div class="ecs-video-insights__input-wrapper--secret-keys">
          <div class="ecs-video-insights__input-wrapper--secret-keys__inputs">
            <cs-input
              v-model="destinationBucketName"
              :label="`${items[active].text} Bucket Name`"
              class="ecs-video-insights__input-wrapper-inputs"
            />
            <cs-input
              v-model="destinationBucketPath"
              :label="`${items[active].text} Bucket Path`"
              class="ecs-video-insights__input-wrapper-inputs"
            />
          </div>
          <div class="ecs-video-insights__input-wrapper--secret-keys__inputs">
            <cs-input
              v-model="destinationAccessKeyId"
              :label="`${items[active].text} Access Key ID`"
              class="ecs-video-insights__input-wrapper-inputs"
            />
            <cs-input
              v-model="destinationSecretAccessKey"
              :label="`${items[active].text} Secret Access Key`"
              class="ecs-video-insights__input-wrapper-inputs"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="cs-textstyle-detail-heading my-2">
      Web Hook URL <span class="light-txt"> (Optional)</span>
    </div>
    <cs-input
      v-model="webHookURL"
      placeholder="Enter URL"
      class="ecs-video-insights__webhook-url"
    >
    </cs-input>
    <cs-button class="ecs-button" :disabled="!valid" @click="analyzeVideo">
      Analyze
    </cs-button>
  </div>
</template>

<script>
import CreateVideoInsightJob from '@/gql/videoinsight/CreateVideoInsightJob.gql';
const awsImgUrl = require('@/assets/images/AWS.svg');
const gcpImgUrl = require('@/assets/images/gcp.svg');
const azureImgUrl = require('@/assets/images/Azure.svg');
const doImgUrl = require('@/assets/images/do.svg');

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: 0,
      items: [
        {
          text: 'AWS',
          destinationProvider: 'AWS',
          img: awsImgUrl,
        },
        {
          text: 'Google Cloud',
          destinationProvider: 'GCP',
          img: gcpImgUrl,
        },
        {
          text: 'Digital Ocean',
          destinationProvider: 'DIGITALOCEAN',
          img: doImgUrl,
        },
        {
          text: 'Azure',
          destinationProvider: 'AZURE',
          img: azureImgUrl,
        },
      ],
      destinationBucketName: null,
      destinationBucketPath: null,
      destinationAccessKeyId: null,
      destinationSecretAccessKey: null,
      webHookURL: null,
    };
  },
  computed: {
    showDesinationUrl() {
      const { features } = this.data;
      return features.includes('object-detection-and-recognition');
    },

    valid() {
      return (
        !!this.destinationBucketName &&
        !!this.destinationBucketPath &&
        !!this.destinationAccessKeyId &&
        !!this.destinationSecretAccessKey
      );
    },
  },
  watch: {
    active: {
      handler() {
        this.destinationBucketName = '';
        this.destinationBucketPath = '';
        this.destinationAccessKeyId = '';
        this.destinationSecretAccessKey = '';
      },
    },
  },
  methods: {
    async analyzeVideo() {
      await this.$apollo.mutate({
        mutation: CreateVideoInsightJob,
        variables: {
          destinationProvider: this.items[this.active].destinationProvider,
          sourceProvider: this.data.sourceProvider,
          projectId: this.$route.params.id,
          sourceBucketPath: this.data.sourceBucketPath,
          sourceBucketName: this.data.sourceBucketUrl,
          sourceAccessKey: this.data.sourceAccessKeyId,
          sourceUrl: this.data.sourceUrl,
          webhookUrl: this.webhookUrl ? this.webhookUrl : '',
          sourceSecretKey: this.data.sourceSecretAccessKey,
          destinationAccessKey: this.destinationAccessKeyId,
          destinationBucketName: this.destinationBucketName,
          destinationBucketPath: this.destinationBucketPath,
          destinationSecretKey: this.destinationSecretAccessKey,
          features: this.data.features,
        },
      });
      this.$emit('close-modal');
    },
    toggleSource(i) {
      this.active = i;
    },
  },
};
</script>

<style scoped>
.ecs-modal-description {
  margin-bottom: 32px;
}
.ecs-button {
  float: right;
}
.ecs-video-insights__active-items {
  background: var(--cs-primary-lightest) !important;
  border: 1px solid #a2d5bf !important;
}
.ecs-video-insights__active-items i {
  color: var(--cs-gray-06);
}
.ecs-video-insights__non-active-items {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  height: 60px;
  border-radius: 3px;
  width: 100px;
  background: var(--cs-gray-01);
  color: var(--cs-gray-05);
  cursor: pointer;
  border: 1px solid var(--cs-gray-02);
}
.ecs-video-insights__non-active-items i {
  color: var(--cs-gray-03);
}
.ecs-video-insights__non-active-items img {
  width: 80px;
}
.ecs-video-insights__list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.ecs-video-insights__list > div > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ecs-video-insights__input-wrapper {
  background: var(--cs-gray-01);
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
}
.ecs-video-insights__input-wrapper-inputs {
  width: 100%;
}
.ecs-video-insights__input-wrapper--secret-keys__inputs {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
}
.ecs-video-insights__settings {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.ecs-video-insights__settings-input {
  width: 80%;
}
.ecs-video-insights__aws-img {
  padding-left: 5px;
  width: 40px !important;
}
.ecs-video-insights__webhook-url {
  width: 100%;
  margin-bottom: 32px;
}
.light-txt {
  color: var(--cs-gray-04);
}
</style>
